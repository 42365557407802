import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import cloudinary from "cloudinary/lib/cloudinary";

import "./App.css";
// import Register from "./pages/register";
// import Home from "./pages/home";
// import UploadReceiptPage from "./pages/UploadReceiptPage";
// import Upload_meetandgreet from "./pages/Upload_meetandgreet";
// import RedeemRewards from "./pages/redeem_rewards";
// import RegisterPets from "./pages/register_pets";
// import AddAddress from "./pages/add_address";
// import EditProfile from "./pages/edit_profile";
// import Profile from "./pages/profile";
// import Edit_address from "./pages/edit_address";
// import EditPets from "./pages/EditPets";
// import SuccessRegisterPage from "./pages/SuccessRegisterPage";
// import AlertSuscess from "./pages/alertSuscess";
// import Add_addressRedeem from "./pages/add_addressRedeem";
// import UserRoute from "./components/routes/UserRoute";
// import Delivery from "./pages/delivery";
// import PetShop from "./pages/petShop";
// import Login from "./pages/Login";
// import LineLoginProd from "./pages/LineLoginProd";
// import LuckyDraw from "./pages/lucky_draw";
// import LuckyDrawDetail from "./pages/lucky_draw_detail";
// import LuckyDrawExchange from "./pages/lucky_draw_exchange";
// import SelectCampaign from "./pages/select_campaign";

const Register = lazy(() => import("./pages/register"));
const Home = lazy(() => import("./pages/home"));
const UploadReceiptPage = lazy(() => import("./pages/UploadReceiptPage"));
const Upload_meetandgreet = lazy(() => import("./pages/Upload_meetandgreet"));
const RedeemRewards = lazy(() => import("./pages/redeem_rewards"));
const RegisterPets = lazy(() => import("./pages/register_pets"));
const AddAddress = lazy(() => import("./pages/add_address"));
const EditProfile = lazy(() => import("./pages/edit_profile"));
const Profile = lazy(() => import("./pages/profile"));
const Edit_address = lazy(() => import("./pages/edit_address"));
const EditPets = lazy(() => import("./pages/EditPets"));
const SuccessRegisterPage = lazy(() => import("./pages/SuccessRegisterPage"));
const AlertSuscess = lazy(() => import("./pages/alertSuscess"));
const Add_addressRedeem = lazy(() => import("./pages/add_addressRedeem"));
const UserRoute = lazy(() => import("./components/routes/UserRoute"));
const Delivery = lazy(() => import("./pages/delivery"));
const PetShop = lazy(() => import("./pages/petShop"));
const Login = lazy(() => import("./pages/Login"));
const LineLoginProd = lazy(() => import("./pages/LineLoginProd"));
const LuckyDraw = lazy(() => import("./pages/lucky_draw"));
const LuckyDrawDetail = lazy(() => import("./pages/lucky_draw_detail"));
const LuckyDrawExchange = lazy(() => import("./pages/lucky_draw_exchange"));
const SelectCampaign = lazy(() => import("./pages/select_campaign"));

cloudinary.config({
  cloud_name: process.env.REACT_APP_CLOUD_NAME,
  api_key: process.env.REACT_APP_API_KEY,
  api_secret: process.env.REACT_APP_API_SECRET,
});

// Public routes array
const publicRoutes = [
  { path: "/", element: <LineLoginProd /> },
  { path: "/login", element: <Login /> },
  { path: "/select_campaign", element: <SelectCampaign /> },
  { path: "/register", element: <Register /> },
  { path: "/successRegister", element: <SuccessRegisterPage /> },
];

// User-specific routes array (nested under UserRoute)
const userRoutes = [
  { path: "/home", element: <Home /> },
  { path: "/Upload_meetandgreet", element: <Upload_meetandgreet /> },
  { path: "/redeem_rewards", element: <RedeemRewards /> },
  { path: "/register_pets", element: <RegisterPets /> },
  { path: "/add_address", element: <AddAddress /> },
  { path: "/delivery", element: <Delivery /> },
  { path: "/edit_profile", element: <EditProfile /> },
  { path: "/profile", element: <Profile /> },
  { path: "/edit_address/:id", element: <Edit_address /> },
  { path: "/EditPets/:id", element: <EditPets /> },
  { path: "/alertSuscess/:page", element: <AlertSuscess /> },
  { path: "/delivery/:id/:point", element: <Delivery /> },
  { path: "/add_addressRedeem", element: <Add_addressRedeem /> },
  { path: "/petShop", element: <PetShop /> },
  { path: "/lucky-draw", element: <LuckyDraw /> },
  { path: "/lucky-draw-detail", element: <LuckyDrawDetail /> },
  { path: "/lucky-draw-exchange", element: <LuckyDrawExchange /> },
  { path: "/upload-receipt", element: <UploadReceiptPage /> },
];

const App = () => {
  // Prodution  /////////
  // const liffId = "1660688630-NeqEEQ62";
  // const liffId2 = "2003848518-pYEnWv62";
  // const liffIdProd = "2004859316-2pyBv7Y8";
  //------------------//

  // ------------UAT Meet&Greet ------------- //
  // const liffId = "1661110329-vPxWabm6";
  // const liffId2 = "2006383440-LQAjPBb4";
  // const liffIdProd = "2006383449-yQVd7GZN";

  // return (
  //   <Router>
  //     <div className="App">
  //       <Routes>
  //         {/* <Route exact path="/" component={LineLoginProd}></Route> */}
  //         <Route exact path="/" component={Register}></Route>
  //         <Route exact path="/login" component={Login}></Route>
  //         <Route
  //           exact
  //           path="/select_campaign"
  //           component={SelectCampaign}
  //         ></Route>
  //         <Route exact path="/register" component={Register}></Route>
  //         <Route
  //           exact
  //           path="/successRegister"
  //           component={SuccessRegisterPage}
  //         ></Route>
  //         <UserRoute exact path="/home" component={Home}></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/Upload_meetandgreet"
  //           component={Upload_meetandgreet}
  //         ></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/redeem_rewards"
  //           component={RedeemRewards}
  //         ></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/register_pets"
  //           component={RegisterPets}
  //         ></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/add_address"
  //           component={AddAddress}
  //         ></UserRoute>
  //         <UserRoute exact path="/delivery" component={Delivery}></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/edit_profile"
  //           component={EditProfile}
  //         ></UserRoute>
  //         <UserRoute exact path="/profile" component={Profile}></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/edit_address/:id"
  //           component={Edit_address}
  //         ></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/EditPets/:id"
  //           component={EditPets}
  //         ></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/alertSuscess/:page"
  //           component={AlertSuscess}
  //         ></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/delivery/:id/:point"
  //           component={Delivery}
  //         ></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/add_addressRedeem"
  //           component={Add_addressRedeem}
  //         ></UserRoute>
  //         <UserRoute exact path="/petShop" component={PetShop}></UserRoute>
  //         <UserRoute exact path="/lucky-draw" component={LuckyDraw}></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/lucky-draw-detail"
  //           component={LuckyDrawDetail}
  //         ></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/lucky-draw-exchange"
  //           component={LuckyDrawExchange}
  //         ></UserRoute>
  //         <UserRoute
  //           exact
  //           path="/upload-receipt"
  //           component={UploadReceiptPage}
  //         ></UserRoute>
  //         <Route exact path="/lineLoginProd" component={LineLoginProd}></Route>
  //       </Routes>
  //     </div>
  //   </Router>
  // );

  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" component={LineLoginProd} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/select_campaign" component={SelectCampaign} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/successRegister" component={SuccessRegisterPage} />

            {/* <UserRoute>
              <Switch> */}
                <UserRoute exact path="/home" component={Home} />
                <UserRoute exact path="/Upload_meetandgreet" component={Upload_meetandgreet} />
                <UserRoute exact path="/redeem_rewards" component={RedeemRewards} />
                <UserRoute exact path="/register_pets" component={RegisterPets} />
                <UserRoute exact path="/add_address" component={AddAddress} />
                <UserRoute exact path="/delivery/:id/:point" component={Delivery} />
                <UserRoute exact path="/edit_profile" component={EditProfile} />
                <UserRoute exact path="/profile" component={Profile} />
                <UserRoute exact path="/edit_address/:id" component={Edit_address} />
                <UserRoute exact path="/EditPets/:id" component={EditPets} />
                <UserRoute exact path="/alertSuscess/:page" component={AlertSuscess} />
                <UserRoute exact path="/add_addressRedeem" component={Add_addressRedeem} />
                <UserRoute exact path="/petShop" component={PetShop} />
                <UserRoute exact path="/lucky-draw" component={LuckyDraw} />
                <UserRoute exact path="/lucky-draw-detail" component={LuckyDrawDetail} />
                <UserRoute exact path="/lucky-draw-exchange" component={LuckyDrawExchange} />
                <UserRoute exact path="/upload-receipt" component={UploadReceiptPage} />
              {/* </Switch>
            </UserRoute> */}
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
